
import { Prop, Vue, Component } from 'vue-property-decorator'
import { EnhancedPost } from '~base/common/types'

@Component
export default class PostType extends Vue {
  @Prop({ type: Object, required: true }) readonly post!: EnhancedPost
  @Prop({ type: String, default: '' }) readonly categories!: string
  @Prop({ type: Boolean, default: false }) readonly isFirstItem!: boolean
  @Prop({ type: Number, required: true }) readonly index!: number

  get title() {
    return this.post.title
  }

  get permalink() {
    if (this.post.externalLink) {
      return this.post.externalLink
    }

    return this.post.link
  }

  navigate() {
    if (this.post.externalLink) {
      window.open(this.post.externalLink, '_blank')
    } else {
      this.$router.push(this.post.link)
    }
  }
}
