import {
  MetaPropertyCharset,
  MetaPropertyEquiv,
  MetaPropertyName,
  MetaPropertyMicrodata,
  MetaPropertyProperty,
  LinkPropertyBase,
  LinkPropertyHref,
  LinkPropertyHrefCallback,
  MetaInfo,
} from 'vue-meta/types'
import { OperationVariables } from 'apollo-client'

/**
 * Common types used in more than one post types
 */
export interface Term {
  slug?: string
  name: String
}

export interface TermList {
  nodes: Term[]
}

export interface Thumbnail {
  height: number
  width: number
  src: string
  srcset: string
  sizes: string
}

export interface Author {
  slug?: string
  databaseId?: number
  name: string
  email: string
  avatar: {
    url: string
  }
}

export interface Page {
  id?: string
  pageId?: number
  type: 'page'
  slug: string
  title: string
  author?: {
    node: Author
  }
  dateGmt: string
  modifiedGmt: string
  content: string
  blocks?: Block[]
  thumbnail?: Thumbnail
  template?: {
    __typename: string
  }
  showPaywall: boolean
}

export interface Post {
  id?: string
  postId: number
  slug: string
  title: string
  excerpt?: string
  blocks: Block[]
  thumbnail?: Thumbnail
  tags?: {
    nodes?: Term[]
  }
  categories?: {
    nodes?: Term[]
  }
  author?: {
    node: Author
  }
  readingTime: number
  dateGmt: string
  modifiedGmt?: string
  isComment: boolean
  externalLink?: string
}

export interface EnhancedPost extends Post {
  type: 'post' | 'apa-ticker'
  enhanced: boolean
  hasPicture: boolean
  isFree?: boolean
  renderComponent: string
  relevantCategories?: Term[]
  categoryClasses: string
  link: string
  permalink: string
}

export enum BlockType {
  DELETE = 'DELETE',
  Author = 'Author',
  APALivestream = 'APALivestream',
  DCXImage = 'DCXImage',
  HtmlBlock = 'HtmlBlock',
}

export interface BlockAttribute {
  key: string
  value: string | undefined | null
}

export interface Block {
  t: string | BlockType
  ot?: string | undefined
  h?: string
  a?: BlockAttribute[]
  data?: {
    node: Author
  }
  innerBlocks?: Block[]
}

export interface Embed {
  block: string
  script?: string
  id?: string
  onSuccess?: (block: Block | undefined) => void
  match?: (block: Block | undefined) => boolean
  matchedBlock?: Block | undefined
  setup?: (block: Block | undefined) => void
  appendTo?: () => HTMLElement | null
}

export interface PageInfo {
  hasPreviousPage: boolean
  hasNextPage: boolean
  endCursor?: string
}

export interface SearchResultEntry extends EnhancedPost {
  publication: string
}

export interface SearchResult {
  pageInfo: PageInfo
  hits: number
  nodes: SearchResultEntry[]
}

/**
 * Types for most subscribed/read articles
 */

export interface MostReadArticleInitialData {
  mostsubscribed: MostReadArticleNodes
}

export interface MostReadArticleNodes {
  nodes: MostReadArticle[]
}

export interface MostReadArticle {
  title: string
  url: string
  slug: string
  post?: MostReadArticlePost
}

export interface MostReadArticlePost {
  title: string
  author: MostReadArticlePostAuthor
  thumbnail?: Thumbnail
  categories: TermList
}

export interface MostReadArticlePostAuthor {
  name: string
  email: string
}

export interface MostReadArticles {
  nodes?: MostReadArticle[]
}

export interface Layout {
  component: object
  items: number
  skipOnRepeat?: boolean
}

export interface PostsInLayout {
  layout: number
  component: object
  posts: Post[]
}

/**
 * Sidebars
 */
export interface Sidebar {
  position: string
  widgets: SidebarWidget[]
}

export interface SidebarWidget {
  component: string
  props: string
  initialData: string | null
  posInMainFeed: number
  showOnMobile: boolean
  title: string
}

/**
 * Browser stuff
 */
export enum Display {
  mobile = '320px',
  sm = '576px',
  md = '768px',
  lg = '992px',
}

export type VueMetaProperty =
  | MetaPropertyCharset
  | MetaPropertyEquiv
  | MetaPropertyName
  | MetaPropertyMicrodata
  | MetaPropertyProperty

export type VueLinkProperty =
  | LinkPropertyBase
  | LinkPropertyHref
  | LinkPropertyHrefCallback

export type VueMetaInfo = MetaInfo
export type ApolloOperationVariables = OperationVariables
export interface OpenGraphData {
  title: string
  permalink: string
  description: string
  ogImage: string
  author: string
  [key: string]: string
}

export interface Tab {
  tab: string
  label: string
  url?: string
}

export interface NewstickerEntry {
  postId: number
  type: string
  link: string
  id: string
  slug: string
  title: string
  dateGmt: string
  modifiedGmt: string
}

export interface NewstickerResult {
  pageInfo: PageInfo
  hits: number
  nodes: NewstickerEntry
}

export interface NewstickerData {
  search: NewstickerResult
}

export interface MenuEntry {
  id: string
  label: string
  url?: string
  title?: string
  target?: string
  cssClasses: string[]
  parentId: string
}
export interface Menu {
  menuItems: {
    nodes: MenuEntry[]
  }
}

export interface MenuItem extends MenuEntry {
  childItems?: MenuItem[]
  click?: () => void
}

export interface AgbItem {
  agb: string
  error: string
  id: string
  required: boolean
}

export interface PianoCustomVariables {
  name: string
  value: any
}

export interface ApaLoginParams {
  aboNo: string
  aboPassword: string
  issueDate?: string
  issueMutation?: string
  issueId?: string
}

export interface AbocardActivationData {
  custno: string
  lastname: string
  email: string
}
export type ListingPageType = 'tags' | 'category' | 'search'

export interface PageSettings {
  headerComponent: () => object | string
  headerComponentInArticle?: boolean
  pageClass: string
  head: MetaInfo
  layouts: Layout[]
  sidebar?: string
}

export interface DcxImage {
  caption?: string
  width: number
  height: number
  img: string
}

export type CookiebotConsent = {
  necessary: boolean
  preferences: boolean
  marketing: boolean
  statistics: boolean
}

export type ScriptsAfterGdprType = {
  [key: string]: {
    innerHTML?: string
    src?: string
    async?: boolean
    defer?: boolean
    type?: string
    json?: string
  }[]
}
